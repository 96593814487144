// Variables
@import "variables";

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/v4-shims";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

//Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap.scss";

//Datatables
@import "~datatables.net-bs/css/dataTables.bootstrap.min.css";

//AdminLTE
@import "~adminlte/dist/css/AdminLTE.min.css";
@import "~adminlte/dist/css/skins/skin-green.min.css";